
import { Vue, Prop, Component } from "vue-property-decorator";
import VueMarkdown from "vue-markdown";
import { BlogCardData as IBlogCardData } from "@/interfaces/BlogCardData.interface";

@Component({
  components: {
    VueMarkdown,
  },
})
export default class BlogPostCard extends Vue {
  @Prop() private postData!: IBlogCardData;
}
