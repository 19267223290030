import { render, staticRenderFns } from "./BlogPostCard.vue?vue&type=template&id=76de2dec&scoped=true"
import script from "./BlogPostCard.vue?vue&type=script&lang=ts"
export * from "./BlogPostCard.vue?vue&type=script&lang=ts"
import style0 from "@/assets/css/card-ui/blog-post-card.scss?vue&type=style&index=0&id=76de2dec&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76de2dec",
  null
  
)

export default component.exports